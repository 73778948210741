import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import dateFormat from 'dateformat';

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioboxField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function, CustomLinks, removeNonNumeric, AppraisalFormFieldMapping, DynamicFormID } from "../common/utils"
import { navigate } from "@reach/router"
import axios from "axios"
import * as qs from "query-string"

import $ from "jquery"

function RegSrchForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [startDate, setStartDate] = useState();

  var get_department = props?.department ? props?.department : props.location?.state?.department ? props.location?.state?.department : "";

  const [prop_department, setPropDepartment] = useState(get_department ? get_department : 'Residential');

  const [formvalues, setFormvalues] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var department = prop_department;

  var prop_page_url = props.location?.state?.page_url ? props.location?.state?.page_url : "";

  var type = props?.type ? props?.type : props.location.state && props.location.state.type ? props.location.state.type : "Agency";
  
  var sub_type = props?.sub_type ? props?.sub_type : props.location.state && props.location.state.sub_type ? props.location.state.sub_type : "Other";

  var to_user_id = props?.to_user_id ? props?.to_user_id : props.location.state && props.location.state.to_user_id ? props.location.state.to_user_id : DynamicFormID[department.toLowerCase()];

  var fields = [
    {
        element: "config",
        formname: `Register for updates - ${department}`,
        form_type: "register_your_search",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "email_alert_user",
        email_temp_admin: "email_alert_admin",
        email_server_func: "register_your_search",
        event_tracking: "register_your_search",
        page_url: props.location?.pathname,
        email_subject_user: `Register for updates`,
        email_subject_admin: `Register for updates`,
      }
    ]    
    
    fields.push({
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Next Step",
        class: "btn-primary col-md-12 full_wdth",
        labelClass: "",
        customClass:"btn-wrapper"
      },
    )

  var fields2 = [
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
      // {
      //   grpmd: "12",
      //   label: "Department",
      //   placeholder: "",
      //   name: "department",
      //   element: "select",
      //   required: true,
      //   values: ['Residential'],
      //   labelClass: "annual-txt",
      //   class: "full_wdth_box prop_type_opt",
      //   divclass: "prop_type_opt"
      // },
      {
        grpmd: "12",
        label: "What are you looking for?",
        placeholder: prop_department ? prop_department : "",
        name: "property_department",
        element: "select",
        required: false,
        values: ['Residential', 'Commercial'],
        labelClass: "annual-txt",
        class: "full_wdth_box",
        divclass: "sm_wdth_100",
        selected_value: prop_department
      },
      {
        grpmd: "12",
        label: "What areas are you looking in?",
        placeholder: "",
        name: "property_address",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter the property address."
      },
      {
        grpmd: "6",
        label: "Buying or Letting",
        placeholder: "",
        name: "property_status",
        element: "select",
        required: false,
        values: ['Buying', 'Letting'],
        labelClass: "annual-txt",
        class: "full_wdth_box prop_type_opt",
        divclass: "bed_cls sm_wdth_100"
      },
    ];

  var res_fields = [
      {
        grpmd: "6",
        label: "Property Type",
        placeholder: "",
        name: "property_type",
        element: "select",
        required: false,
        values: ['House', 'Flat'],
        labelClass: "annual-txt",
        class: "full_wdth_box prop_type_opt",
        divclass: "prop_type_opt sm_wdth_100"
      },
      
      {
        grpmd: "6",
        label: "Min. Bedrooms",
        placeholder: "",
        name: "min_bed",
        element: "select",
        required: false,
        values: ['Studio', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        labelClass: "annual-txt",
        class: "full_wdth_box",
        divclass: "bed_cls sm_wdth_100"
      },
      {
        grpmd: "6",
        label: "Max. Price (£)",
        placeholder: "1000",
        name: "max_price",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "sm_wdth_100"
      },
      {
        grpmd: "12",
        label: "Can you please provide any other details about your search?",
        placeholder: "Type them here...",
        name: "message",
        element: "textarea",
        class: "mb-4",
        required: false,
        rows:"4",
        labelClass: "annual-txt"
      },
      // {
      //   grpmd: "6",
      //   name: "previous",
      //   type:"button",
      //   element: "button",
      //   value: "Previous",
      //   class: "btn-primary  full_wdth",
      //   labelClass: "",
      //   customClass:"btn-wrapper prev_btn col-md-6 sm_wdth_100"
      // },
      {
        grpmd: "12",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit Enquiry",
        class: "btn-primary full_wdth",
        labelClass: "",
        customClass:"btn-wrapper submit_btn col-12 sm_wdth_100"
      },
      {
        text: 'By clicking submit enquiry you agree to our <a href="'+CustomLinks.privacy_policy+'" target="_blank">privacy policy</a>. You can change your requirement or unsubscribe at any time.',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      
  ];

  var comm_fields = [
    {
      grpmd: "6",
      label: "Property Type",
      placeholder: "",
      name: "property_type",
      element: "select",
      required: false,
      values: ['Offices', 'Retail', 'Leisure & Hospitality', 'Industrial & Logistics', 'Studio & Workshop', 'Medical', 'Educational', 'Investment', 'Land & Development', 'Other'],
      labelClass: "annual-txt",
      class: "full_wdth_box prop_type_opt",
      divclass: "prop_type_opt sm_wdth_100",
    },

    {
      grpmd: "4",
      label: "Min. Size",
      placeholder: "10",
      name: "min_size",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "bed_cls sm_wdth_100"
    },

    {
      grpmd: "4",
      label: "Max. Size",
      placeholder: "100000",
      name: "max_size",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "bed_cls sm_wdth_100"
    },

    {
      grpmd: "4",
      label: "Size Metric",
      placeholder: "",
      name: "size_metric",
      element: "select",
      required: false,
      values: ['sqft', 'sqm', 'desks', 'acres', 'hectares'],
      labelClass: "annual-txt",
      class: "full_wdth_box prop_type_opt",
      divclass: "prop_type_opt sm_wdth_100"
    },

    {
      grpmd: "12",
      label: "Max. Price (£)",
      placeholder: "1000",
      name: "max_price",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "sm_wdth_100"
    },

    {
      grpmd: "12",
      label: "Can you please provide any other details about your search?",
      placeholder: "Type them here...",
      name: "message",
      element: "textarea",
      class: "mb-4",
      required: false,
      rows:"4",
      labelClass: "annual-txt"
    },
    // {
    //   grpmd: "6",
    //   name: "previous",
    //   type:"button",
    //   element: "button",
    //   value: "Previous",
    //   class: "btn-primary  full_wdth",
    //   labelClass: "",
    //   customClass:"btn-wrapper prev_btn col-md-6 sm_wdth_100"
    // },
    {
      grpmd: "12",
      name: "SUBMIT",
      type:"submit",
      element: "button",
      value: "Submit Enquiry",
      class: "btn-primary full_wdth",
      labelClass: "",
      customClass:"btn-wrapper submit_btn col-12 sm_wdth_100"
    },
    {
      text: 'By clicking submit enquiry you agree to our <a href="'+CustomLinks.privacy_policy+'" target="_blank">privacy policy</a>. You can change your requirement or unsubscribe at any time.',
      element: "html",
      class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
    },
    
  ];

  const handlechange = event => {
    // remove initial empty spaces
    console.log("select_event",event);
    if(event.type === "select"){
      if(event.name === "property_department"){
        setPropDepartment(event.label)
      }
      event.value = event.value.trimStart()
    } else{
      if(event.target.name === "telephone" || event.target.name === "max_price"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();
      formvalues['g-recaptcha-response'] = token;

      formData.append('data', JSON.stringify(formvalues));

      //console.log("formvalues", formvalues)
        if(formvalues.email){
          postFormData(formData).then(async apiRes => {
              window.grecaptcha.reset(); 
          });
          Mail_Function(formvalues);    
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+fields[0].event_tracking,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });

          setShowerror(false);
          // setThankyou(true);
          setTimeout(() => {
            sessionStorage.setItem("previousFormPage", window.location.pathname)
            navigate("/thank-you")
          }, 200);

          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    form_obj_arr.filter(item => item[1].required === true).map(item =>{
      if(nextStep === false){
        if(item[1].validationMessage !== ""){
          $(".step1 .error_msg."+item[1].name).addClass('show');
        } else{
          $(".step1 .error_msg."+item[1].name).removeClass('show');
        }
      } else{
        if(item[1].validationMessage !== ""){
          $(".step2 .error_msg."+item[1].name).addClass('show');
        } else{
          $(".step2 .error_msg."+item[1].name).removeClass('show');
        }
      }
    })

    //console.log("form ==>", form)

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      
      if(nextStep === false && (form.first_name.validationMessage !== "" || form.last_name.validationMessage !== "" || form.email.validationMessage !== "" || form.telephone.validationMessage !== "")){
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
      } else{
        setNextStep(true);  
        setShowerror(false);
        setValidated(false);
      }  
      // if(nextStep === true && (form[12].value === "" || form[13].checked === false || form[14].checked === false || form[15].value === ""  || form[17].value === "")){
      if(nextStep === true && (form.property_address.validationMessage === "")){
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
      }
      
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      event.preventDefault();
        setNextStep(false);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        const formsdata = (event.target);
        const json = {}
        Object.keys(formsdata).map(key => (
          //json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
          json[formsdata[key].name] = (formsdata[key].checked === true) ? formsdata[key].value : formsdata[key].value
        ))

        var date_time = dateFormat(json.preferred_date, "yyyy-dd-mm");

        json['name'] = json.first_name+' '+json.last_name;
        json['email_temp_user'] = fields[0].email_temp_user;
        json['email_temp_admin'] = fields[0].email_temp_admin;
        json['email_subject_user'] = fields[0].email_subject_user;
        json['email_subject_admin'] = fields[0].email_subject_admin;
        json['g-recaptcha-response'] = token;
        json['date_time'] = date_time;
        json['enquirytype'] = json.form_type;

        json['message'] = json.department === "residential" ? 
            "Search Type: "+ json.property_status+"<br>Property Type: "+ json.property_type+"<br>"+"Bedrooms: "+json.min_bed+"<br>"+"Price: "+json.max_price+'<br><br>'+json.message 
          :
            "Search Type: "+ json.property_status+"<br>Property Type: "+ json.property_type+"<br>"+"Size: "+json.min_size+" - "+json.max_size+" "+json.size_metric+"<br>"+"Price: "+json.max_price+'<br><br>'+json.message;

        json['extra'] = json.department === "residential" ? 
          {"property_address": json.property_address, "department": json.department, "property_url": json.property_url, "min_bed" : json.min_bed, "max_price" : json.max_price, "property_type" : json.property_type, "property_status" : json.property_status, "type_label": json.type_label, "type": json.type, "sub_type_label": json.sub_type_label, "sub_type": json.sub_type, "to_user_id": json.to_user_id, "subject" : json.email_subject_user, "to_email_id" : json.to_email_id} 
        : 
          {"property_address": json.property_address, "department": json.department, "property_url": json.property_url, "max_price" : json.max_price, "property_type" : json.property_type, "property_status" : json.property_status, "size_metric" : json.size_metric, "min_size" : json.min_size, "max_size" : json.max_size, "type_label": json.type_label, "type": json.type, "sub_type_label": json.sub_type_label, "sub_type": json.sub_type, "to_user_id": json.to_user_id, "subject" : json.email_subject_user};


        //console.log("formsdata_json", json);

        setFormvalues(json);

        recaptchaRef.current.execute();

        setValidated(false);

        // reset form
        const form = event.target
        form.reset();
        setStartDate();
        
    }
  };

  //console.log("prop_department", prop_department);

  var field_set = prop_department.toLowerCase() === "residential" ? res_fields : comm_fields;
  
  return (
    <div className="form">
      <div ref={myRef}></div>
      {/* {showerror && <div className="alert alert-danger">
        {fields[0].error_text}
      </div>} */}
      
      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={fields[0].formname} />
        <input type="hidden" name="property_url" value={prop_page_url ? prop_page_url : process.env.GATSBY_SITE_URL+props.location.pathname} />
        
        <input type="hidden" name="to_email_id" value={process.env.GATSBY_MAIL_TO_AGENCY} />
        <input type="hidden" name="department" value={department} />
        <input type="hidden" name="admin_email" value={department.toLowerCase() === "commercial" ? "Yes" : "No"} />

        <input type="hidden" name="to_user_id" value={to_user_id} />

        <input type="hidden" name="type_label" value={type} />
        <input type="hidden" name="type" value={type && AppraisalFormFieldMapping.Type[type]} />
        <input type="hidden" name="sub_type_label" value={sub_type} />
        <input type="hidden" name="sub_type" value={sub_type && AppraisalFormFieldMapping.SubType[type][sub_type]} />
        
        <input type="hidden" name="bot-field" />

        <Form.Row>
            <div className={`step1 ${nextStep === true ? "hideStep" : "showStep"}`}>
              {/* <h4>Personal Details</h4> */}
              {fields.map((field, index) => {
                  if ( "input" === field.element ) {
                    return (
                      <InputField
                        name={field.name}
                        grpmd={field.grpmd}
                        label={field.label}
                        ref={field.ref}
                        type={field.type}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.element}~${index}`}
                        pattern={field.patternchk}
                        handlechange={handlechange}
                        startDate={field.type === 'valuation_date_time' ? startDate : ''}
                        setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                        divclass={field.divclass}
                        errorMsg={field.errorMsg}
                      />
                    );
                  }

                  if ("button" === field.element) {
                    return (
                      <>
                      
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                      />
                      
                      </>
                    );
                  }
                })
              }
            </div>
            
            {fields2.map((field, index) => {
              if ("captcha" === field.element) {
                  return (
                    <ReCaptchaBox
                      fieldClass={field.class}
                      captRef={field.captchaRef}
                      key={`${field.element}~${index}`}
                      handleonVerify={handleonVerify}
                    />
                  );
                }
              })
            }
          
            <div className={`step2 ${nextStep === false ? "hideStep" : "showStep"}`}>
            {/* <h4>Register your search</h4> */}
            {fields2.map((field, index) => {
                if ( "input" === field.element ) {
                  return (
                    <InputField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      pattern={field.patternchk}
                      handlechange={handlechange}
                      startDate={field.type === 'valuation_date_time' ? startDate : ''}
                      setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                      divclass={field.divclass}
                      errorMsg={field.errorMsg}
                    />
                  );
                }
                if ("select" === field.element) {
                  return (
                    <SelectField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                      divclass={field.divclass}
                      selectedValue={field.selected_value}
                    />
                  );
                }
              })
            }

            {field_set.map((field, index) => {
                if ( "input" === field.element ) {
                  return (
                    <InputField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      pattern={field.patternchk}
                      handlechange={handlechange}
                      startDate={field.type === 'valuation_date_time' ? startDate : ''}
                      setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                      divclass={field.divclass}
                      errorMsg={field.errorMsg}
                    />
                  );
                }
                if ("select" === field.element) {
                  return (
                    <SelectField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                      divclass={field.divclass}
                    />
                  );
                }

                if ("radio" === field.element) {
                  return (
                    <RadioboxField
                      name={field.name}
                      ref={field.ref}
                      values={field.value}
                      fieldClass={field.class}
                      label={field.label}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.name}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("textarea" === field.element) {
                  return (
                    <>
                    
                    <TextAreaField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      rows={field.rows}
                      fieldClass={field.class}
                      labelClass={field.labelClass}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                    />
                    </>
                  );
                }
                if ("html" === field.element) {
                  return (
                    <HtmlBox
                      text={field.text}
                      fieldClass={field.class}
                      key={`${field.element}~${index}`}
                    />
                  );
                }
                
                if ("button" === field.element) {
                  return (
                    <>
                    {field.name === "previous" ? 
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                        handlClick={setNextStep}
                      />
                      :
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                      />                      
                    }                    
                    </>
                  );
                }
              })
            }
            </div>          
        </Form.Row>
        
      </Form>
    </div>
  );
}


const RegSrchPage = (props) => (
  <RegSrchForm {...props} />
  
)

export default RegSrchPage