import React from "react";
import Select, { components } from "react-select";
import { get, isString } from "lodash";
import "./SelectOption.scss";

const selectOption = props => {
  return (
    <div className={"select-option " + (props.isError ? "has-error" : "")}>
      <Select
        isSearchable={false}
        placeholder={props.placeHolder}
        aria-labelledby={props.id}
        value={props.value}
        defaultValue={props.DefaultValue}
        onBlur={props.onBlur}
        onChange={props.handleChange}
        options={props.options}
        classNamePrefix={"select-opt"}
        {...props.other}
      />
    </div>
  );
};
export default selectOption;
